import React, { ReactNode, useRef, useState } from "react";
import { useConfiguredAxios } from "../Utils/AxiosInstance";
import axios, { CancelTokenSource } from "axios";
import { IContext, QueryStateT } from "../Utils/Types";

interface DomainsContext extends IContext {
  stopFetchData: () => void;
}

interface DomainsProviderProps {
  children: ReactNode;
}

const DomainsContext = React.createContext<DomainsContext>({
  data: [],
  count: 0,
  fetchData: async () => [],
  isLoading: true,
  stopFetchData: () => {},
});

/**
 * This component is a wrapper for the entire application.
 * It provides the context for the domains.
 * @param children - The children of the component.
 */
const DomainsProvider = ({children}: DomainsProviderProps) => {
  const axiosInstance = useConfiguredAxios();

  // ----- States ----- //
  const [isLoading, setIsLoading] = useState(true);
  const [domains, setDomains] = useState([]);
  const [count, setCount] = useState(0);

  // ----- Ref for CancelToken ----- //
  const cancelTokenRef = useRef<CancelTokenSource | null>(null);

  const fetchDomains = async (queryStateT?: QueryStateT) => {
    const {filters, sort, page, pageSize} = queryStateT || {};

    // Cancel the previous request if it exists
    if (cancelTokenRef.current) {
      cancelTokenRef.current.cancel("Request canceled due to new fetch.");
    }

    // Create a new CancelToken
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenRef.current = cancelTokenSource;

    setIsLoading(true);
    const filtersJson = filters?.length
      ? `&filters=${encodeURIComponent(JSON.stringify(filters))}`
      : "";
    const sortJson = sort?.length
      ? `&sort=${encodeURIComponent(JSON.stringify(sort))}`
      : "";

    try {
      const response = await axiosInstance.get(
        `/api/domains?page=${page}&pageSize=${pageSize}` + filtersJson + sortJson,
        {cancelToken: cancelTokenSource.token}
      );
      const data = response.data;
      setDomains(data.domains);
      setCount(data.total);
      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Fetch request canceled:", error.message);
      } else {
        console.error("Error fetching domains:", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const stopFetchData = () => {
    if (cancelTokenRef.current) {
      cancelTokenRef.current.cancel("Fetch stopped by user.");
      cancelTokenRef.current = null; // Reset the reference
    }
  };

  // ----- Render ----- //
  return (
    <DomainsContext.Provider
      value={{
        data: domains,
        fetchData: fetchDomains,
        count,
        isLoading,
        stopFetchData,
      }}
    >
      {children}
    </DomainsContext.Provider>
  );
};

export { DomainsContext, DomainsProvider };
