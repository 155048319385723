import React, { ReactNode, useRef, useState } from "react";
import { useConfiguredAxios } from "../Utils/AxiosInstance";
import axios, { CancelTokenSource } from "axios";
import { IContext, ListT, QueryStateT } from "../Utils/Types";

interface ListsContext extends IContext {
  getGroups: () => Promise<any[]>;
  handleListEdit: (list: ListT) => void;
  handleListCreate: (list: ListT) => void;
  handleListDelete: (id: number) => void;
  stopFetchData: () => void;
}

interface ListsProviderProps {
  children: ReactNode;
}

const ListsContext = React.createContext<ListsContext>({
  data: [],
  count: 0,
  fetchData: async () => [],
  isLoading: true,
  getGroups: async () => [],
  handleListEdit: () => {},
  handleListCreate: () => {},
  handleListDelete: () => {},
  stopFetchData: () => {},
});

/**
 * This component is a wrapper for the entire application.
 * It provides the context for the lists.
 * @param children - The children of the component.
 */
const ListsProvider = ({children}: ListsProviderProps) => {
  const axiosInstance = useConfiguredAxios();

  // ----- States ----- //
  const [isLoading, setIsLoading] = useState(true);
  const [lists, setLists] = useState<ListT[]>([]);
  const [count, setCount] = useState(0);

  // ----- Ref for CancelToken ----- //
  const cancelTokenRef = useRef<CancelTokenSource | null>(null);

  const fetchLists = async (queryStateT?: QueryStateT) => {
    const {filters, sort, page, pageSize} = queryStateT || {};

    // Cancel the previous request if it exists
    if (cancelTokenRef.current) {
      cancelTokenRef.current.cancel("Request canceled due to new fetch.");
    }

    // Create a new CancelToken
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenRef.current = cancelTokenSource;

    setIsLoading(true);
    const filtersJson = filters?.length
      ? `&filters=${encodeURIComponent(JSON.stringify(filters))}`
      : "";
    const sortJson = sort?.length
      ? `&sort=${encodeURIComponent(JSON.stringify(sort))}`
      : "";

    try {
      const response = await axiosInstance.get(
        `/api/lists?page=${page}&pageSize=${pageSize}` + filtersJson + sortJson,
        {cancelToken: cancelTokenSource.token}
      );
      const data = response.data;
      setLists(data.lists);
      setCount(data.total);
      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Fetch request canceled:", error.message);
      } else {
        console.error("Error fetching lists:", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const stopFetchData = () => {
    if (cancelTokenRef.current) {
      cancelTokenRef.current.cancel("Fetch stopped by user.");
      cancelTokenRef.current = null; // Reset the reference
    }
  };

  const handleEdit = (list: ListT) => {
    const newLists = lists.map((l: ListT) => (l.id === list.id ? list : l));
    setLists(newLists);
  };

  const handleCreate = (list: ListT) => {
    const newLists = [...lists, list];
    setLists(newLists);
  };

  const handleDelete = (id: number) => {
    const newLists = lists.filter((list: ListT) => list.id !== id);
    setLists(newLists);
  };

  const getGroups = async () => {
    return await axiosInstance.get(`/api/lists/groups`).then((response) => {
      return response.data;
    });
  };

  // ----- Render ----- //
  return (
    <ListsContext.Provider
      value={{
        data: lists,
        count,
        fetchData: fetchLists,
        isLoading,
        getGroups,
        handleListEdit: handleEdit,
        handleListCreate: handleCreate,
        handleListDelete: handleDelete,
        stopFetchData,
      }}
    >
      {children}
    </ListsContext.Provider>
  );
};

export { ListsContext, ListsProvider };
