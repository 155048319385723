// ----- Modules ----- //
import React from "react";
import moment from "moment/moment";
import { enqueueSnackbar } from "notistack";

// ----- MUI ----- //
import {
  GridCsvExportOptions,
  GridCsvGetRowsToExportParams,
  gridExpandedSortedRowIdsSelector,
  GridRenderCellParams,
  GridToolbarColumnsButton,
  useGridApiContext
} from "@mui/x-data-grid-premium";
import { Box, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import UserIcon from "@mui/icons-material/PersonOutlineOutlined";

// ----- Components ----- //
import Tab, { handleColumnsSelector } from "./Tab";
import List from "../../components/modal/List";
import ActionBtns from "../../components/ActionBtns";
import CellBoolean from "../../components/datagrid/CellBoolean";

// ----- Utils ----- //
import { ListsContext } from "../../contexts/ListsProvider";
import { Pages } from "../../Utils/Types";
import { getSelectedTheme } from "../../Utils/Colors";
import CellDateTime from "../../components/datagrid/CellDateTime";

const getFilteredRows = ({apiRef}: GridCsvGetRowsToExportParams) =>
  gridExpandedSortedRowIdsSelector(apiRef);

function CustomToolbar() {
  const apiRef = useGridApiContext();

  // ----- Functions ----- //
  const handleExport = (options: GridCsvExportOptions) => {
    try {
      if (apiRef.current.getRowsCount() === 0)
        enqueueSnackbar('No lists to export.', {variant: 'warning'});
      else {
        const date = moment().format('YYYY-MM-DD');
        options.fileName = `lists-filtered_${date}.csv`;
        apiRef.current.exportDataAsCsv(options);
        enqueueSnackbar('Lists exported successfully!', {variant: 'success'});
      }
    } catch (e) {
      enqueueSnackbar('Failed to export lists.', {variant: 'error'});
    }
  };

  // ----- Render ----- //
  return (
    <Box sx={{py: 1.5}}>
      <Box sx={{display: 'none'}} id={'column-visibility'}><GridToolbarColumnsButton/></Box>
      <ActionBtns
        tab={Pages.lists}
        SelectColumns={handleColumnsSelector}
        exportElements={() => handleExport({getRowsToExport: getFilteredRows})}
        CreateElement={
          <List/>
        }
      />
    </Box>
  );
}

const columns = [
  {
    field: '*Edit List*', headerName: '', minWidth: 0, width: 30,
    filterable: false,
    headerAlign: 'center',
    renderHeader: () =>
      <Box sx={{
        color: getSelectedTheme().darkText,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <EditIcon/>
      </Box>,
    renderCell: (params: any) => {
      return <List edit={true} list={params.row}/>;
    },
  },
  {field: 'id', headerName: 'ID', width: 70, hide: true},
  {field: 'name', headerName: 'List', width: 250},
  {
    field: 'group',
    headerName: 'Group',
    width: 150,
  },
  {
    field: 'is_ds', headerName: 'DS', width: 100, align: 'center', type: 'boolean',
    renderCell: (params: any) => CellBoolean(params.row.is_ds),
  },
  {
    field: 'sync', headerName: 'Sync', width: 100, align: 'center', type: 'boolean',
    renderCell: (params: any) => CellBoolean(params.row.sync),
  },
  {
    field: 'use_proxy', headerName: 'Proxy', width: 100, align: 'center', type: 'boolean',
    renderCell: (params: any) => CellBoolean(params.row.use_proxy),
  },
  {
    field: 'accounts_count',
    headerName: 'Accounts',
    width: 75,
    align: 'center',
    headerAlign: 'center',
    type: 'number',
    renderHeader: () => <Tooltip title={'Number of accounts using this list'} placement={'top'} arrow>
      <UserIcon sx={{fontSize: '24px', color: getSelectedTheme().darkText}}/>
    </Tooltip>,
    filterable: false,
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 300,
    sortable: false,
  },
  {
    field: 'updated_at', headerName: 'Updated At', width: 130, align: 'right',
    renderCell: (params: GridRenderCellParams<any, Date>) =>
      <CellDateTime value={params.row.updated_at !== params.row.created_at ? params.row.updated_at : null}/>,
    filterable: false
  },
  {
    field: 'created_at', headerName: 'Created At', width: 130, align: 'right',
    renderCell: (params: any) => <CellDateTime value={params.value}/>,
    filterable: false
  }
];

/**
 * Lists Page component. Displays a list of lists.
 */
const Lists = () => {
  return (
    <Tab columns={columns} toolbar={CustomToolbar} context={ListsContext}/>
  );
};

export default Lists;
