// ----- Module ----- //
import React, { useContext } from "react";
import moment from "moment";
import { useConfiguredAxios } from "../../Utils/AxiosInstance";

// ----- MUI ----- //
// Components
import { Box, Button, Divider, IconButton, Modal, Tooltip, Typography, } from "@mui/material";
// Icons
import CreditCardIcon from "@mui/icons-material/CreditCard";

// ----- Utils ----- //
import { CreditCardsContext } from "../../contexts/CreditCardsProvider";
import { CreditCardT } from "../../Utils/Types";
import { ModalStyle } from "../../Utils/Theme/Theme";
import { getSelectedTheme } from "../../Utils/Colors";
import { FONTS } from "../../index";
import { AccountsContext } from "../../contexts/AccountsProvider";
import Title from "./components/Title";
import DynamicForm from "./components/DynamicForm";

const ccInfoFields = [
  {label: "Type", key: "type_id", isTypeName: true},
  {label: "Last 4", key: "last_4"},
  {label: "CVV", key: "cvv"},
  {label: "Expiration Date", key: "exp", placeholder: "MM/YY"},
  {label: "Currency", key: "currency"},
  {label: "Safekey", key: "safekey", placeholder: "Only for AMEX"},
];

/**
 * Credit Card Modal
 * - Used to create or edit a credit card associated with an account.
 * @param props {account_id: number, cc_id: number}
 * - account_id: The account id on which the credit card is associated.
 * - cc_id: The credit card id. Can be null if creating a new credit card.
 */
const CreditCard = (props: { account_id: number, cc_info: CreditCardT, cc2_info: CreditCardT }) => {
  const axiosInstance = useConfiguredAxios();
  // ----- Context ----- //
  const {types} = useContext(CreditCardsContext);
  const {data: accounts, handleEdit} = useContext(AccountsContext);

  // ----- Props ----- //
  const {account_id, cc_info, cc2_info} = props;

  // ----- States ----- //
  const [ccInfo, setCCInfo] = React.useState(cc_info ? cc_info : {} as CreditCardT);
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  // ----- Functions ----- //
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  /**
   * Handle the change of the credit card info.
   * @param key{string} - The key of the credit card info.
   * @param value{string | number | {}} - The value of the credit card info.
   */
  const handleCCInfoChange = (key: string, value: string | number | {}) => {
    if (!ccInfo) return;
    setCCInfo({...ccInfo, [key]: value});
  };

  /**
   * Send a request to the server to update the credit card info.
   */
    // const handleSubmit = useCallback(() => {
    //   if (!ccInfo) return;
    //   setLoading(true);
    //   axiosInstance.post(`/api/creditcards/update/${account_id}`, ccInfo)
    //     .then(() => {
    //         ccInfo.type = types.find((t: CcT) => t.id === ccInfo.type_id) as CcT;
    //         setLoading(false);
    //         ccInfo.updated_at = moment().format('YYYY-MM-DD HH:mm:ss');
    //
    //         const account = accounts.find((a: AccountT) => a.id === account_id) as AccountT;
    //         account.cc_id = ccInfo.id;
    //         account.cc_info = ccInfo;
    //         handleEdit(account);
    //
    //         handleClose();
    //         enqueueSnackbar('Credit Card updated successfully!', {variant: 'success'});
    //       }
    //     ).catch((error) => {
    //     enqueueSnackbar('Failed to update credit card.', {variant: 'error'});
    //     console.log(error);
    //   });
    // }, [account_id, ccInfo]);

    // Check if the credit card is expired.
  const expDate = ccInfo ? moment(ccInfo.exp, 'MM/YY') : null;

  // ----- Render ----- //
  return (
    <>
      <Tooltip title={!ccInfo?.last_4 || !ccInfo?.cvv || !ccInfo?.exp ? 'Incomplete'
        : expDate && expDate.isBefore(moment()) ? 'Expired' : ''} placement={'top'} disableInteractive>
        <IconButton aria-label="Show Credit Card Info" component="span"
                    sx={{p: 0.25, position: 'relative', left: '-7px'}}
                    onClick={handleOpen}>
          <CreditCardIcon sx={{
            color: !ccInfo?.last_4 || !ccInfo?.cvv || !ccInfo?.exp ? getSelectedTheme().warning : expDate && expDate.isBefore(moment()) ? getSelectedTheme().error : getSelectedTheme().accent,
            fontSize: '30px'
          }}/>
        </IconButton>
      </Tooltip>

      <Modal
        open={open}
        onClose={handleClose}
        style={{backdropFilter: "blur(2px)"}}
      >
        <Box sx={ModalStyle}>
          <Title title={'View Credit Cards'} icon={<CreditCardIcon sx={{fontSize: '35px'}}/>}/>

          <Divider/>

          <Box sx={{display: 'flex', gap: 2, flexDirection: 'column', py: 2}}>

            <DynamicForm data={cc_info} fields={ccInfoFields} prefix="1" types={types}/>

            <DynamicForm data={cc2_info} fields={ccInfoFields} prefix="2" types={types}/>

          </Box>


          <Divider/>

          <Box
            sx={{display: 'flex', width: '100%', alignItems: 'center', pt: 2, gap: 1}}>

            {ccInfo?.id &&
              <Typography color={getSelectedTheme().darkText} lineHeight={1} fontFamily={FONTS}>
                Created: {moment(ccInfo.created_at).format('MM/DD/YYYY HH:mm:ss')}
                <br/>
                {ccInfo.created_at !== ccInfo.updated_at &&
                  <span>Updated: {moment(ccInfo.updated_at).format('MM/DD/YYYY HH:mm:ss')}</span>}

              </Typography>
            }

            <Box sx={{ml: 'auto'}}>
              <Button variant="text" sx={{color: getSelectedTheme().accent}}
                      onClick={handleClose}>Close</Button>
              {/*<Button variant="contained"*/}
              {/*        sx={{backgroundColor: getSelectedTheme().accent, color: 'white'}}*/}
              {/*        disabled={*/}
              {/*          !ccInfo ||*/}
              {/*          !ccInfo.type_id ||*/}
              {/*          (ccInfo.type_id === 1 && !ccInfo.safekey) ||*/}
              {/*          !ccInfo.last_4 ||*/}
              {/*          ccInfo.last_4.length !== 4 ||*/}
              {/*          !ccInfo.cvv ||*/}
              {/*          !ccInfo.exp ||*/}
              {/*          !ccInfo.currency ||*/}
              {/*          moment(ccInfo.exp, 'MM/YY').isBefore(moment()) ||*/}
              {/*          loading*/}
              {/*        }*/}
              {/*        onClick={handleSubmit}>*/}
              {/*  {loading ? <Box sx={{pt: 0.1}}><CircularProgress size={20}/></Box>*/}
              {/*    : 'Save'}*/}
              {/*</Button>*/}
            </Box>
          </Box>

        </Box>
      </Modal>
    </>
  );
};

export default CreditCard;
