import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, LinearProgress, Paper } from "@mui/material";
import { DarkDataGridPremium } from "../../Utils/Theme/Theme";
import { IContext } from "../../Utils/Types";

interface TabProps<T extends IContext> {
  columns: any;
  toolbar: React.JSXElementConstructor<any>;
  context: React.Context<T>;
}

/**
 * Default list component
 *   - columns: columns for the list
 *   - data: data for the list
 *   - toolbar: custom toolbar that appears above the list
 *   - loading: whether the list is loading
 * @param props - props for the component
 */
function Tab<T extends IContext>(props: TabProps<T>) {
  const {columns, toolbar, context} = props;
  const contextValue = React.useContext(context);
  const {fetchData, stopFetchData, data, count, isLoading} = contextValue;

  const [filterModel, setFilterModel] = useState<any>({items: []});
  const [sortModel, setSortModel] = useState<any>([]);
  const debounceRef = useRef<NodeJS.Timeout | null>(null);

  // Refs to store the latest state
  const filterModelRef = useRef<any>({items: []});
  const sortModelRef = useRef<any>([]);

  const mapOperatorNames = (operator: string) => {
    const operatorMapping = {
      contains: "like",
      equals: "eq",
      before: "lt",
      after: "gt",
      onOrBefore: "lte",
      onOrAfter: "gte",
      is: "eq",
    } as any;
    return operatorMapping[operator] || operator;
  };

  const search = useCallback(
    (page = 0, pageSize = 100) => {
      const filters = filterModelRef.current.items.map((item: any) => ({
        field: item.field,
        operator: mapOperatorNames(item.operator),
        value: item.value,
      }));

      const sort = sortModelRef.current.map((item: any) => ({
        field: item.field,
        order: item.sort,
      }));

      fetchData({filters, sort, page, pageSize});
    },
    [fetchData, mapOperatorNames] // No need for filterModel and sortModel as dependencies
  );

  const debounceSearch = (callback: () => void, delay: number) => {
    if (debounceRef.current) clearTimeout(debounceRef.current);
    debounceRef.current = setTimeout(callback, delay);
  };

  const handleModelChange = (model: any) => {
    stopFetchData(); // Stop any ongoing fetch immediately

    if (!Array.isArray(model)) {
      setFilterModel(model);
      filterModelRef.current = model; // Update the ref
    } else {
      setSortModel(model);
      sortModelRef.current = model; // Update the ref
    }

    // Schedule the new search with debounce
    debounceSearch(() => search(), 500);
  };

  const handlePaginationModelChange = (params: any) => {
    // Stop any ongoing fetch immediately
    stopFetchData();

    // Schedule the new search with debounce
    debounceSearch(() => search(params.page, params.pageSize), 250);
  };

  useEffect(() => {
    search(); // Initial fetch
  }, []);

  // Cleanup debounce timer on component unmount
  useEffect(() => {
    return () => {
      if (debounceRef.current) clearTimeout(debounceRef.current);
    };
  }, []);

  // ----- Render ----- //
  return (
    <Box sx={{height: "95%", pt: 3}}>
      <Paper
        elevation={3}
        id={"content"}
        sx={{
          borderRadius: "7px",
          boxShadow: "0px 0px 13px rgba(0, 0, 0, 0.25)",
          height: "100%",
          mx: 12,
          px: 1,
        }}
      >
        <Box
          sx={{
            mx: "auto",
            px: 3,
            height: "100%",
            pb: 3,
          }}
        >
          <DarkDataGridPremium
            loading={isLoading || false}
            rows={data || []}
            columnHeaderHeight={50}
            columns={columns || []}
            slots={{
              toolbar: toolbar || null,
              [isLoading && data.length !== 0 ? "loadingOverlay" : ""]: LinearProgress,
            }}
            initialState={{
              density: "compact",
              columns: {
                columnVisibilityModel: {
                  id: false,
                  manager_status: false,
                },
              },
              sorting: {
                sortModel: [{field: "created_at", sort: "desc"}],
              },
            }}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "row-even" : "row-odd"
            }
            disableRowGrouping
            disableAggregation
            headerFilters
            onFilterModelChange={handleModelChange}
            onSortModelChange={handleModelChange}
            filterMode="server"
            sortingMode="server"
            pagination
            onPaginationModelChange={handlePaginationModelChange}
            paginationMode="server"
            pageSizeOptions={[100]}
            rowCount={count}
          />
        </Box>
      </Paper>
    </Box>
  );
}

export const handleColumnsSelector = () => {
  const columnButton = document.querySelector("#column-visibility button") as HTMLButtonElement;
  if (columnButton) columnButton.click();
};

export default Tab;
