import React from "react";
import { Box, TextField, Typography } from "@mui/material";

interface FieldSchema {
  label: string;
  key: string;
  placeholder?: string;
  isTypeName?: boolean; // Whether the field represents the type name
}

interface DynamicFormProps {
  data: Record<string, any>;
  fields: FieldSchema[];
  prefix: string;
  types: { id: number; name: string }[];
}

const DynamicForm: React.FC<DynamicFormProps> = ({data, fields, prefix, types}) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        alignItems: "center",
      }}
    >
      <Typography sx={{minWidth: 30, fontWeight: "bold", textAlign: "center"}}>
        {prefix}
      </Typography>

      {/* Inputs */}
      {fields.map((field) => {
        try {
          if (field.isTypeName) {
            const typeName = types?.find((type) => type.id === data?.[field.key])?.name || "N/A";
            return (
              <TextField
                key={field.key}
                label={field.label}
                value={typeName}
                placeholder={field.placeholder || ""}
                variant="outlined"
                size="small"
                InputProps={{
                  readOnly: true,
                }}
                disabled
                fullWidth
                InputLabelProps={{shrink: true}}
              />
            );
          }

          // For other fields, safely access data
          const value = data?.[field.key] || "";
          return (
            <TextField
              key={field.key}
              label={field.label}
              value={value}
              placeholder={field.placeholder || ""}
              variant="outlined"
              size="small"
              InputProps={{
                readOnly: true,
              }}
              disabled
              fullWidth
              InputLabelProps={{shrink: true}}
            />
          );
        } catch (error) {
          console.error(`Error rendering field ${field.label}:`, error);
          return (
            <TextField
              key={field.key}
              label={field.label}
              value="Error"
              variant="outlined"
              size="small"
              InputProps={{
                readOnly: true,
              }}
              disabled
              fullWidth
              InputLabelProps={{shrink: true}}
              error
            />
          );
        }
      })}
    </Box>
  );
};

export default DynamicForm;
