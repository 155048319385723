import React, { ReactNode, useRef, useState } from "react";
import { IContext, ProxyT, QueryStateT } from "../Utils/Types";
import { useConfiguredAxios } from "../Utils/AxiosInstance";
import axios, { CancelTokenSource } from "axios";

interface ProxiesContext extends IContext {
  getAvailableProxies: (accountType?: string) => Promise<ProxyT[]>;
  stopFetchData: () => void;
}

interface ProxiesProviderProps {
  children: ReactNode;
}

const ProxiesContext = React.createContext<ProxiesContext>({
  data: [],
  count: 0,
  fetchData: async () => [],
  stopFetchData: () => {},
  isLoading: true,
  getAvailableProxies: () => Promise.resolve([]),
});

const ProxiesProvider = ({children}: ProxiesProviderProps) => {
  const axiosInstance = useConfiguredAxios();

  // ----- States ----- //
  const [isLoading, setIsLoading] = useState(true);
  const [proxies, setProxies] = useState([] as ProxyT[]);
  const [count, setCount] = useState(0);

  // ----- Ref for CancelToken ----- //
  const cancelTokenRef = useRef<CancelTokenSource | null>(null);

  // ----- Functions ----- //

  const fetchProxies = async (queryStateT?: QueryStateT) => {
    const {filters, sort, page, pageSize} = queryStateT || {};

    // Cancel any ongoing request
    if (cancelTokenRef.current) {
      cancelTokenRef.current.cancel("Request canceled due to new fetch.");
    }

    // Create a new CancelToken
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenRef.current = cancelTokenSource;

    setIsLoading(true);
    const filtersJson = filters?.length
      ? `&filters=${encodeURIComponent(JSON.stringify(filters))}`
      : "";
    const sortJson = sort?.length
      ? `&sort=${encodeURIComponent(JSON.stringify(sort))}`
      : "";

    try {
      const response = await axiosInstance.get(
        `/api/proxies?page=${page}&pageSize=${pageSize}` +
        filtersJson +
        sortJson,
        {cancelToken: cancelTokenSource.token}
      );
      const data = response.data;
      setProxies(data.proxies);
      setCount(data.total);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Fetch request canceled:", error.message);
      } else {
        console.error("Error fetching proxies:", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const stopFetchData = () => {
    if (cancelTokenRef.current) {
      cancelTokenRef.current.cancel("Fetch stopped by user.");
      cancelTokenRef.current = null; // Reset the reference
    }
  };

  const getAvailableProxies = async (accountType?: string) => {
    return await axiosInstance
      .get(`/api/proxies/available?account_type=${accountType || ""}`)
      .then((response) => {
        return response.data;
      });
  };

  // ----- Render ----- //
  return (
    <ProxiesContext.Provider
      value={{
        data: proxies,
        fetchData: fetchProxies,
        stopFetchData,
        count,
        isLoading,
        getAvailableProxies,
      }}
    >
      {children}
    </ProxiesContext.Provider>
  );
};

export { ProxiesContext, ProxiesProvider };
