import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useConfiguredAxios } from "../Utils/AxiosInstance";
import axios, { CancelTokenSource } from "axios";
import { AccountT, IContext, QueryStateT } from "../Utils/Types";
import WebSocketManager from "../services/WebSocketManager";

interface AccountsContext extends IContext {
  handleEdit: (account: AccountT) => void;
  handleCreate: (account: AccountT) => void;
  handleDelete: (id: number) => void;
  stopFetchData: () => void;
}

interface AccountsProviderProps {
  children: ReactNode;
}

const AccountsContext = React.createContext<AccountsContext>({
  data: [],
  count: 0,
  fetchData: async () => [],
  isLoading: true,
  handleEdit: () => {},
  handleCreate: () => {},
  handleDelete: () => {},
  stopFetchData: () => {},
});

/**
 * This component is a wrapper for the entire application.
 * It provides the context for the accounts.
 * @param children - The children of the component.
 */
const AccountsProvider = ({children}: AccountsProviderProps) => {
  const axiosInstance = useConfiguredAxios();

  // ----- States ----- //
  const [isLoading, setIsLoading] = useState(true);
  const [accounts, setAccounts] = useState([] as AccountT[]);
  const [count, setCount] = useState(0);
  const [pageInfo, setPageInfo] = useState({page: 1, pageSize: 100});

  // ----- Ref for CancelToken ----- //
  const cancelTokenRef = useRef<CancelTokenSource | null>(null);

  const handleAccountInfoChange = (method: string, data: AccountT) => {
    setAccounts((currentAccounts) => {
      switch (method) {
        case "POST":
          return [data, ...currentAccounts];
        case "PUT":
          return currentAccounts.map((a) => (a.id === data.id ? {...a, ...data} : a));
        case "DELETE":
          return currentAccounts.filter((a) => a.id !== data.id);
        default:
          return currentAccounts;
      }
    });
  };

  useEffect(() => {
    const handleWebSocketMessage = (message: any) => {
      if (message.type === "ACCOUNTS") handleAccountInfoChange(message.method, message.data.account);
    };

    // Create a new instance of WebSocketManager
    const webSocketManager = new WebSocketManager(handleWebSocketMessage);

    // Cleanup function
    return () => {
      webSocketManager.disconnect(); // Disconnect WebSocket on unmount
    };
  }, []);

  const fetchAccounts = async (queryState?: QueryStateT) => {
    let {filters, sort, page, pageSize} = queryState || {};
    setIsLoading(true);

    if (page === undefined) page = pageInfo.page;
    if (pageSize === undefined) pageSize = pageInfo.pageSize;

    setPageInfo({page, pageSize});

    // Cancel the previous request if it exists
    if (cancelTokenRef.current) {
      cancelTokenRef.current.cancel("Request canceled due to new fetch.");
    }

    // Create a new CancelToken
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenRef.current = cancelTokenSource;

    const filtersJson = filters?.length
      ? `&filters=${encodeURIComponent(JSON.stringify(filters))}`
      : "";
    const sortJson = sort?.length
      ? `&sort=${encodeURIComponent(JSON.stringify(sort))}`
      : "";

    try {
      const response = await axiosInstance.get(
        `/api/accounts?page=${page}&pageSize=${pageSize}` + filtersJson + sortJson,
        {cancelToken: cancelTokenSource.token}
      );
      const data = response.data;
      setAccounts(data.accounts);
      setCount(data.total);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Fetch request canceled:", error.message);
      } else {
        console.error("Error fetching accounts:", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const stopFetchData = () => {
    if (cancelTokenRef.current) {
      cancelTokenRef.current.cancel("Fetch stopped by user.");
      cancelTokenRef.current = null; // Reset the reference
    }
  };

  // ----- Functions ----- //
  const handleEdit = (account: AccountT) => {
    setAccounts((currentAccounts) =>
      currentAccounts.map((a: AccountT) => (a.id === account.id ? account : a))
    );
  };

  const handleCreate = (account: AccountT) => {
    setAccounts((currentAccounts) => [account, ...currentAccounts]);
  };

  const handleDelete = (id: number) => {
    setAccounts((currentAccounts) => currentAccounts.filter((a: AccountT) => a.id !== id));
  };

  // ----- Render ----- //
  return (
    <AccountsContext.Provider
      value={{
        data: accounts,
        count,
        fetchData: fetchAccounts,
        isLoading,
        handleEdit,
        handleCreate,
        handleDelete,
        stopFetchData,
      }}
    >
      {children}
    </AccountsContext.Provider>
  );
};

export { AccountsContext, AccountsProvider };
